/*----------  Body  ----------*/

body
{
	@include font-p($font-base, $weight-regular, 1.2, $brand-dark);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 { 
	font-weight: $weight-bold;
}

.grecaptcha-badge{
	opacity: 0;
	cursor: none;
}

.blackBg{
	background-color: #333;
}